import React, { useState } from "react";
import Section from "components/Section";
import Card from "components/Card";
import { Carousel } from "react-bootstrap";
import {
  CarouselCaptions,
  CarouselCaption,
  CarouselCaptionTitle,
  CarouselCaptionContent,
  CarouselContainer,
} from "./shared";

export default function WhatIsANarrativeCard() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <CarouselContainer>
      <Section>
        <Carousel
          interval={null}
          fade
          activeIndex={index}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <CarouselCaption isActive={index === 0}>
              <CarouselCaptionTitle>
                Narratives Emerge from Audiences
              </CarouselCaptionTitle>
              <CarouselCaptionContent>
                An audience is a group of people defined by values—their goals
                and ideas about how the world works. When audiences interact
                with stories, narratives emerge, patterns in how people see
                particular issues. This means that to identify and measure
                narrative, we need to start by identifying stories actual people
                actually engage with. Once we’ve identified those stories, we
                put thousands of them in front of actual audiences to understand
                the narratives they see.
              </CarouselCaptionContent>
            </CarouselCaption>
          </Carousel.Item>
          <Carousel.Item>
            <CarouselCaption isActive={index === 1}>
              <CarouselCaptionTitle>
                Narratives Reproduce and Spread
              </CarouselCaptionTitle>
              <CarouselCaptionContent>
                Highly personal narratives may emerge when people process
                stories. For instance, reading about a food bank might trigger a
                narrative about the generosity of Aunt Sue, who runs her
                church’s shelter. We’re not concerned with personal, private
                narratives, but with narratives that are sufficiently sticky,
                persistent, and rooted to show up across, say, online news,
                entertainment television, and music. The ways people respond to
                stories—emotions, clicks, call outs—cause narratives to
                reproduce, spread, and change.
              </CarouselCaptionContent>
            </CarouselCaption>
          </Carousel.Item>
          <Carousel.Item>
            <CarouselCaption isActive={index === 2}>
              <CarouselCaptionTitle>
                Narratives Determine Culture
              </CarouselCaptionTitle>
              <CarouselCaptionContent>
                Narratives constrain what is possible and practical. They limit
                the stories people tell within a culture, sub-culture, story
                medium, or audience. Narratives also serve as atmosphere, a mood
                that colors perception and influences how people create and
                reflect culture. This means that, although the work of narrative
                strategy happens at the level of audience and story—varying the
                what, how, where, and to whom of the stories we’re telling—we
                need to know the whole narrative landscape to understand how the
                stories we tell will land.
              </CarouselCaptionContent>
            </CarouselCaption>
          </Carousel.Item>
        </Carousel>
        <div className="pt-4">
          <Carousel
            interval={null}
            fade
            activeIndex={index}
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/what-is-a-narrative/narratives-emerge-from-audiences.png"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/what-is-a-narrative/narratives-reproduce-and-spread.png"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/what-is-a-narrative/persistent-recurring-narratives-determine-culture.png"
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </Section>
    </CarouselContainer>
  );
}
