import {
  BORDER_RADIUS_SMALL,
  BORDER_RADIUS,
  BORDER_RADIUS_XLARGE,
  BREAKPOINT,
  COLORS,
  getNarrativeTypeColor,
} from "utils/styles";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NARRATIVE_TYPES } from "posts/constants";
import Card from "components/Card";
import { format, parse } from "date-fns";
import { useMediaQuery } from "react-responsive";
import { VictoryArea, VictoryChart, VictoryAxis, VictoryLabel } from "victory";
import {
  ToggleButton as BSToggleButton,
  ToggleButtonGroup as BSToggleButtonGroup,
} from "react-bootstrap";
import { NARRATIVE_MEDIA_TYPES } from "narratives/constants";

const width = 1000;
const height = 500;

const NarrativeCard = styled(Card)`
  text-align: center;
`;

const Container = styled.div`
  max-width: 60rem;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
  text-align: center;
  position: relative;
`;

const ToggleButtonGroup = styled(BSToggleButtonGroup)`
  background: #f2f2f2;
  padding: 0.5rem;
  border-radius: 25px;
  margin-top: 2rem;
`;

const ToggleButton = styled(BSToggleButton)`
  background: #f2f2f2;
  max-width: 200px;
  margin-right: 1rem;
  border-radius: 15px !important;
  box-shadow: none !important;
  border: none !important;

  &.active {
    background-color: #fff !important;
    font-family: "mabry-medium", sans-serif !important;
    font-weight: 500 !important;
  }

  @media ${BREAKPOINT.M} {
    font-size: 0.75rem;
    margin-right: 0.5rem;
  }

  @media ${BREAKPOINT.S} {
    font-size: 0.65rem;
    margin-right: 0.25rem;
  }

  @media ${BREAKPOINT.XS} {
    font-size: 0.55rem;
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0 !important;
  }
`;

const LegendWrapper = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0.5rem;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0.5rem;
  ${BORDER_RADIUS}

  @media ${BREAKPOINT.M} {
    position: static;
    top: auto;
    right: auto;
    width: 100%;
  }
`;

const LegendTitle = styled.li.attrs({ className: "mabry-medium" })`
  margin-bottom: 0.5rem;
`;

const StyledLegendItem = styled.li`
  display: flex;
  align-items: center;
  transition: all 250ms ease-out;
  cursor: pointer;
  opacity: ${({ hoveredNarrative, narrative }) => {
    if (!hoveredNarrative) return 1;
    if (hoveredNarrative === narrative) return 1;
    return 0.2;
  }};

  &::before {
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    ${BORDER_RADIUS}
    background: ${({ narrative }) => getNarrativeTypeColor(narrative)};
    margin-right: 1rem;

    @media ${BREAKPOINT.S} {
      width: 1rem;
      height: 1rem;
      ${BORDER_RADIUS_SMALL}
    }
  }
`;

const TwitterNote = styled.div``;

const getXAxisLabelStyles = (useLargeLabels) => {
  return {
    tickLabels: {
      fontSize: useLargeLabels ? 30 : 15,
      fontFamily: "'mabry-light', sans-serif",
      padding: 10,
    },
    axisLabel: {
      fontSize: useLargeLabels ? 30 : 15,
      fontFamily: "'mabry-medium', sans-serif",
      fontWeight: "500 !important",
      padding: useLargeLabels ? 50 : 30,
    },
  };
};
const getYAxisLabelStyles = (useLargeLabels) => {
  return {
    ...getXAxisLabelStyles(useLargeLabels),
    ticks: { size: 0, stroke: COLORS.BLACK },
    axisLabel: {
      ...getXAxisLabelStyles(useLargeLabels).axisLabel,
      padding: useLargeLabels ? 15 : 5,
    },
  };
};

const LegendItem = (props) => {
  const handleNarrativeMouseEnter = (e) => {
    props.onNarrativeHover(true, props.narrative);
  };
  const handleNarrativeMouseLeave = (e) => {
    props.onNarrativeHover(false, null);
  };

  return (
    <StyledLegendItem
      narrative={props.narrative}
      onMouseEnter={handleNarrativeMouseEnter}
      onMouseLeave={handleNarrativeMouseLeave}
      hoveredNarrative={props.hoveredNarrative}
    >
      {props.children}
    </StyledLegendItem>
  );
};

export default function NarrativesTimelineGraph(props) {
  const useLargeLabels = useMediaQuery({ query: "(max-width: 800px)" });
  const [hovered, setHovered] = useState(null);
  const [narrative, setNarrative] = useState(null);
  const [mode, setMode] = useState(NARRATIVE_MEDIA_TYPES[0].slug);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props.chartData && props.chartData[mode]) {
      setData(props.chartData[mode]);
    } else {
      console.warn(`No data for ${mode}`);
    }
  }, [mode, props.chartData]);

  const renderAreas = ({ fillOnly, lineOnly }) => {
    return Object.keys(data).map((narrativeType, i) => {
      const d = data[narrativeType];
      let opacity = 1;
      if (hovered && narrative) {
        if (narrative !== narrativeType) {
          opacity = 0.1;
        }
      }
      return (
        <VictoryArea
          key={narrativeType}
          width={width}
          height={height}
          interpolation="catmullRom"
          name={`area-${i}`}
          padding={0}
          domainPadding={{ x: [0, 0], y: [0, 10] }}
          animate={{ duration: 250, onLoad: { duration: 0 } }}
          style={{
            data: {
              strokeWidth: 1.5,
              stroke: fillOnly
                ? "transparent"
                : getNarrativeTypeColor(narrativeType),
              opacity,
              fill: lineOnly
                ? "transparent"
                : getNarrativeTypeColor(narrativeType),
              fillOpacity: 0.25,
            },
            parent: { border: "1px solid #ccc" },
          }}
          labels={({ datum }) => {
            return datum.insights || "";
          }}
          x="date"
          y="count"
          labelComponent={<VictoryLabel />}
          data={d}
        />
      );
    });
  };

  const getXAxisLabel = (t) => {
    if (!t.length) return;
    const d = parse(t, "yyyy-MM-dd", new Date());
    if (format(d, "d") > 7) {
      return "";
    }
    if (format(d, "M") === 1) {
      return format(d, "MMM yyy");
    }
    return format(d, "MMM");
  };

  const handleNarrativeHover = (hovered, narrative) => {
    setHovered(hovered);
    setNarrative(narrative);
  };

  if (!data) return null;

  return (
    <Container>
      <ButtonContainer>
        <ToggleButtonGroup
          name="narrative-timeline-button-group"
          type="radio"
          value={mode}
        >
          {NARRATIVE_MEDIA_TYPES.map((val) => (
            <ToggleButton
              key={`toggle-button-${val.slug}`}
              value={val.slug}
              variant="light"
              onClick={() => {
                setMode(val.slug);
              }}
            >
              {val.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </ButtonContainer>
      <NarrativeCard>
        <Wrapper>
          <VictoryChart
            padding={{
              top: 30,
              bottom: useLargeLabels ? 120 : 60,
              left: useLargeLabels ? 60 : 30,
              right: 30,
            }}
            width={width}
            height={height}
          >
            {renderAreas({ fillOnly: true })}
            {renderAreas({ lineOnly: true })}
            <VictoryAxis
              style={getXAxisLabelStyles(useLargeLabels)}
              tickFormat={getXAxisLabel}
            />
            <VictoryAxis
              dependentAxis
              style={getYAxisLabelStyles(useLargeLabels)}
              tickFormat={() => ""}
              label="Attention"
            />
          </VictoryChart>
          <LegendWrapper>
            <LegendTitle>Narratives</LegendTitle>
            <span />
            <LegendItem
              hoveredNarrative={narrative}
              onNarrativeHover={handleNarrativeHover}
              narrative={NARRATIVE_TYPES.VOTE_FOR_X}
            >
              Vote for X
            </LegendItem>
            <LegendItem
              hoveredNarrative={narrative}
              onNarrativeHover={handleNarrativeHover}
              narrative={NARRATIVE_TYPES.CAPITAL_AND_LABOR}
            >
              Capital and Labor
            </LegendItem>
            <LegendItem
              hoveredNarrative={narrative}
              onNarrativeHover={handleNarrativeHover}
              narrative={NARRATIVE_TYPES.SAFETY_NET}
            >
              Safety Net
            </LegendItem>
            <LegendItem
              hoveredNarrative={narrative}
              onNarrativeHover={handleNarrativeHover}
              narrative={NARRATIVE_TYPES.ROBIN_HOOD}
            >
              Robin Hood
            </LegendItem>
            <LegendItem
              hoveredNarrative={narrative}
              onNarrativeHover={handleNarrativeHover}
              narrative={NARRATIVE_TYPES.HELPING_HANDS}
            >
              Helping Hands
            </LegendItem>
            <LegendItem
              hoveredNarrative={narrative}
              onNarrativeHover={handleNarrativeHover}
              narrative={NARRATIVE_TYPES.THE_GRIND}
            >
              The Grind
            </LegendItem>
            <LegendItem
              hoveredNarrative={narrative}
              onNarrativeHover={handleNarrativeHover}
              narrative={NARRATIVE_TYPES.EQUITY_GAP}
            >
              Equity Gap
            </LegendItem>
            <LegendItem
              hoveredNarrative={narrative}
              onNarrativeHover={handleNarrativeHover}
              narrative={NARRATIVE_TYPES.NOT_LIKE_US}
            >
              Not Like Us
            </LegendItem>
          </LegendWrapper>
        </Wrapper>
      </NarrativeCard>
      {mode === "twitter" ? (
        <TwitterNote>
          <strong>Note:</strong> Twitter data unavailable as of January 2022.
        </TwitterNote>
      ) : null}
    </Container>
  );
}
