import { BREAKPOINT, COLORS, getNarrativeTypeColor } from "utils/styles";
import { HomepageContainer } from "components/PageContainer";
import Head from "next/head";
import {
  SecondaryMenuWrapper,
  SecondaryMenuButton,
  SecondaryMenuGroup,
} from "components/Nav/components";
import FlexSpacer from "components/FlexSpacer";
import Nav, { MenuBox, MenuLink } from "components/Nav/Nav";
import SecondaryNav from "components/Nav/SecondaryNav";
import styled from "styled-components";
import { NARRATIVE_TYPES, getNarrativeDescription } from "posts/constants";
import NarrativesTimelineGraph from "components/NarrativesTimelineGraph";
import WhatIsANarrativeCard from "components/OverviewCarousels/WhatIsANarrativeCard";
import NarrativeGrid from "components/NarrativeGrid";
import { getChartData, NARRATIVE_MEDIA_TYPES } from "narratives/constants";
import { add, format } from "date-fns";
import isValid from "date-fns/fp/isValid/index.js";
import Link from "next/link";

import Section from "components/Section";

const Container = styled(HomepageContainer)`
  padding: 0 1rem 2rem 1rem;
  max-width: 80rem;
  margin: 0 auto;
`;

const reduceByAudience = (acc, val) => {
  const previous = acc[val.narrativeType] || [];
  const current = { [val.narrativeType]: [...previous, val] };
  return {
    ...acc,
    ...current,
  };
};

const DATE_12_MONTHS_AGO = format(
  add(new Date(), { months: -12 }),
  "yyy-MM-dd"
);

const filterLast12Months = ({ date }) => {
  return date > DATE_12_MONTHS_AGO;
};

export async function getStaticProps() {
  return {
    props: {
      baseUrl: process.env.BASE_URL,
      chartData: NARRATIVE_MEDIA_TYPES.reduce((acc, val) => {
        return {
          ...acc,
          [val.slug]: getChartData({ media: val.slug })
            .filter(filterLast12Months)
            .reduce(reduceByAudience, {}),
        };
      }, {}),
    },
  };
}

export default function Narratives(props) {
  const { baseUrl, chartData } = props;

  return (
    <div>
      <Head>
        <title>Narratives of Poverty</title>
        <link rel="icon" href="/favcon2.png" />
        <meta
          property="og:image"
          content={`${baseUrl}/images/open-graph.png`}
        />
        <meta
          property="twitter:image"
          content={`${baseUrl}/images/open-graph.png`}
        />
      </Head>
      <main>
        <Nav
          breadCrumbs={[<a href="/narratives">Narratives</a>]}
          secondaryNav={
            <SecondaryMenuWrapper>
              <FlexSpacer />
              <SecondaryMenuGroup>
                <SecondaryMenuButton
                  href="#narratives"
                  text="Narratives"
                  icon="category"
                />
                <SecondaryMenuButton
                  href="#whats-a-narrative"
                  text="What's a Narrative?"
                  icon="help"
                />
                <SecondaryMenuButton
                  href="#comparing-narratives"
                  text="Comparisons"
                  icon="tonality"
                />
              </SecondaryMenuGroup>
            </SecondaryMenuWrapper>
          }
        />
        <Container>
          <Section>
            <a name="narratives"></a>
            <h1>
              Narratives of Poverty <br></br> &amp; Economic Mobility
            </h1>
            <p>
              After analyzing over 500,000 online news stories, 1,000,000
              tweets, and 600,000 TV airings, between January 2020 and May 2021,
              eight distinct narratives emerged. Explore them below.
            </p>
            <footer>
              <NarrativeGrid />
            </footer>
          </Section>

          <Section>
            <a name="whats-a-narrative"></a>
            <h1>What’s a Narrative?</h1>
            <p>
              Our model of narrative starts with audience and ends with stories
              that can shape the future.
            </p>
            <footer>
              <WhatIsANarrativeCard />
            </footer>
          </Section>

          <Section>
            <a name="comparing-narratives"></a>
            <h1>Comparing Narratives</h1>
            <p>
              See how these 8 narratives compare with regard to audience
              attention and media type.
            </p>
            <footer>
              <NarrativesTimelineGraph chartData={chartData} />
            </footer>
          </Section>

          <Section>
            <h1>Choose a Narrative to Learn More</h1>
            <p></p>
            <footer>
              <NarrativeGrid />
            </footer>
          </Section>
        </Container>
        <SecondaryNav />
      </main>
    </div>
  );
}
