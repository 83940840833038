import {
  BREAKPOINT,
  COLORS,
  getNarrativeTypeColor,
  TYPE_SIZE,
} from "utils/styles";
import { NARRATIVE_TYPES, getNarrativeDescription } from "posts/constants";
import styled from "styled-components";
import slugify from "slugify";
import { motion, useReducedMotion } from "framer-motion";
import {
  childVariants,
  wrapperVariants,
  accesibleVariants,
} from "utils/motion";

const Wrapper = styled.div`
  text-align: left;
  ${({ noWrap }) =>
    noWrap
      ? `
        display: grid;
        grid-template-columns: repeat(8, 25vw);
        overflow: scroll;
        
        @media ${BREAKPOINT.M} {
          grid-template-columns: repeat(8, 35vw);
          grid-column-gap: 0.5rem;
        }
        @media ${BREAKPOINT.S} {
          grid-template-columns: repeat(8, 70vw);
        }
      `
      : `
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media ${BREAKPOINT.M} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
  }
  @media ${BREAKPOINT.S} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }`};
`;
const CardContent = styled.span`
  display: block;
  position: absolute;
  top: calc(100% - 2.5rem);
  left: 0.5rem;
  overflow: hidden;
  bottom: 0;
  transition: all 350ms ease-out;
`;
const CardTitle = styled.span.attrs({ className: "mabry-medium" })`
  position: relative;
  z-index: 3;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: ${TYPE_SIZE.S};
  line-height: 1.25;
  border-radius: 6.5px;
  color: ${COLORS.BLACK};
`;
const CardBody = styled.span`
  display: block;
  position: relative;
  z-index: 3;
  opacity: 0;
  line-height: 1.25;
  transition: all 350ms ease-out;
  padding: 0.25rem 0.5rem 0 0.5rem;
  color: ${COLORS.BLACK};
`;
const NarrativeCardImage = styled.span`
  display: block;
`;
const NarrativeCard = styled.a`
  display: block;
  width: 100%;
  height: 300px;
  position: relative;
  
  &:nth-child(1) {
    padding-top: 4vw
    padding-bottom: 1vw;
    padding-right: 2vw;
  }
  &:nth-child(2) {
    padding-left: 6vw;
    padding-right: 4vw;
    padding-bottom: 2vw;
  }
  &:nth-child(3) {
    padding-top: 4vw;
    padding-right: 4vw;
  }
  &:nth-child(4) {
    padding-bottom: 4vw;
  }
  &:nth-child(5) {
    padding-left: 1vw;
    padding-top: 3vw;
    padding-right: 5.5vw;
    padding-bottom: 2vw;
  }
  &:nth-child(6) {
    padding-top: 2vw;
    padding-right: 4vw;
    padding-bottom: 1vw;
  }
  &:nth-child(7) {
    padding-top: 6vw;
    padding-right: 6vw;
  }
  &:nth-child(8) {
    padding-bottom: 3vw;
  }
  
  @media ${BREAKPOINT.M} {
    padding: 0 !important;
  }  
  
  ${CardTitle} {
    background-color: ${({ narrative }) => getNarrativeTypeColor(narrative)};
  }
  
  ${NarrativeCardImage} {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 15px;
    background-color: ${({ narrative }) => getNarrativeTypeColor(narrative)};
    
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 350ms ease-out;
      border-radius: 15px;
      background-position: center;
      background-size: cover;
        background-image: ${({ narrative }) => {
          switch (narrative) {
            case NARRATIVE_TYPES.VOTE_FOR_X:
              return "url(/images/narratives/voteForX.jpg)";
            case NARRATIVE_TYPES.CAPITAL_AND_LABOR:
              return "url(/images/narratives/capitalLabour.jpg)";
            case NARRATIVE_TYPES.SAFETY_NET:
              return "url(/images/narratives/safetyNet.jpg)";
            case NARRATIVE_TYPES.ROBIN_HOOD:
              return "url(/images/narratives/robinHood.jpg)";
            case NARRATIVE_TYPES.HELPING_HANDS:
              return "url(/images/narratives/helpingHands.jpg)";
            case NARRATIVE_TYPES.THE_GRIND:
              return "url(/images/narratives/theGrind.jpg)";
            case NARRATIVE_TYPES.EQUITY_GAP:
              return "url(/images/narratives/equityGap.jpg)";
            case NARRATIVE_TYPES.NOT_LIKE_US:
              return "url(/images/narratives/notLikeUs.jpg)";
          }
        }};
    }
  }
  &:hover,
  &:focus {
    ${CardContent} {
      top: 0.5rem;
    }
    ${CardBody} {
      opacity: 1;
    }
    ${NarrativeCardImage} {
      &::before {
        opacity: 0;
      }
    }
  }
`;

export default function NarrativeGrid(props) {
  const shouldReduceMotion = useReducedMotion();
  let variants = shouldReduceMotion ? accesibleVariants : childVariants;

  return (
    <Wrapper noWrap={props.noWrap} as={motion.div} variants={wrapperVariants}>
      <NarrativeCard
        as={motion.a}
        variants={variants}
        href={`/narratives/${slugify(
          NARRATIVE_TYPES.CAPITAL_AND_LABOR.toLowerCase()
        )}`}
        narrative={NARRATIVE_TYPES.CAPITAL_AND_LABOR}
      >
        <NarrativeCardImage>
          <CardContent>
            <CardTitle>{NARRATIVE_TYPES.CAPITAL_AND_LABOR}</CardTitle>
            <CardBody>
              {getNarrativeDescription(NARRATIVE_TYPES.CAPITAL_AND_LABOR)}
            </CardBody>
          </CardContent>
        </NarrativeCardImage>
      </NarrativeCard>
      <NarrativeCard
        as={motion.a}
        variants={variants}
        href={`/narratives/${slugify(
          NARRATIVE_TYPES.ROBIN_HOOD.toLowerCase()
        )}`}
        narrative={NARRATIVE_TYPES.ROBIN_HOOD}
      >
        <NarrativeCardImage>
          <CardContent>
            <CardTitle>{NARRATIVE_TYPES.ROBIN_HOOD}</CardTitle>
            <CardBody>
              {getNarrativeDescription(NARRATIVE_TYPES.ROBIN_HOOD)}
            </CardBody>
          </CardContent>
        </NarrativeCardImage>
      </NarrativeCard>
      <NarrativeCard
        as={motion.a}
        variants={variants}
        href={`/narratives/${slugify(
          NARRATIVE_TYPES.VOTE_FOR_X.toLowerCase()
        )}`}
        narrative={NARRATIVE_TYPES.VOTE_FOR_X}
      >
        <NarrativeCardImage>
          <CardContent>
            <CardTitle>{NARRATIVE_TYPES.VOTE_FOR_X}</CardTitle>
            <CardBody>
              {getNarrativeDescription(NARRATIVE_TYPES.VOTE_FOR_X)}
            </CardBody>
          </CardContent>
        </NarrativeCardImage>
      </NarrativeCard>
      <NarrativeCard
        as={motion.a}
        variants={variants}
        href={`/narratives/${slugify(
          NARRATIVE_TYPES.SAFETY_NET.toLowerCase()
        )}`}
        narrative={NARRATIVE_TYPES.SAFETY_NET}
      >
        <NarrativeCardImage>
          <CardContent>
            <CardTitle>{NARRATIVE_TYPES.SAFETY_NET}</CardTitle>
            <CardBody>
              {getNarrativeDescription(NARRATIVE_TYPES.SAFETY_NET)}
            </CardBody>
          </CardContent>
        </NarrativeCardImage>
      </NarrativeCard>
      <NarrativeCard
        as={motion.a}
        variants={variants}
        href={`/narratives/${slugify(NARRATIVE_TYPES.THE_GRIND.toLowerCase())}`}
        narrative={NARRATIVE_TYPES.THE_GRIND}
      >
        <NarrativeCardImage>
          <CardContent>
            <CardTitle>{NARRATIVE_TYPES.THE_GRIND}</CardTitle>
            <CardBody>
              {getNarrativeDescription(NARRATIVE_TYPES.THE_GRIND)}
            </CardBody>
          </CardContent>
        </NarrativeCardImage>
      </NarrativeCard>
      <NarrativeCard
        as={motion.a}
        variants={variants}
        href={`/narratives/${slugify(
          NARRATIVE_TYPES.NOT_LIKE_US.toLowerCase()
        )}`}
        narrative={NARRATIVE_TYPES.NOT_LIKE_US}
      >
        <NarrativeCardImage>
          <CardContent>
            <CardTitle>{NARRATIVE_TYPES.NOT_LIKE_US}</CardTitle>
            <CardBody>
              {getNarrativeDescription(NARRATIVE_TYPES.NOT_LIKE_US)}
            </CardBody>
          </CardContent>
        </NarrativeCardImage>
      </NarrativeCard>
      <NarrativeCard
        as={motion.a}
        variants={variants}
        href={`/narratives/${slugify(
          NARRATIVE_TYPES.EQUITY_GAP.toLowerCase()
        )}`}
        narrative={NARRATIVE_TYPES.EQUITY_GAP}
      >
        <NarrativeCardImage>
          <CardContent>
            <CardTitle>{NARRATIVE_TYPES.EQUITY_GAP}</CardTitle>
            <CardBody>
              {getNarrativeDescription(NARRATIVE_TYPES.EQUITY_GAP)}
            </CardBody>
          </CardContent>
        </NarrativeCardImage>
      </NarrativeCard>
      <NarrativeCard
        as={motion.a}
        variants={variants}
        href={`/narratives/${slugify(
          NARRATIVE_TYPES.HELPING_HANDS.toLowerCase()
        )}`}
        narrative={NARRATIVE_TYPES.HELPING_HANDS}
      >
        <NarrativeCardImage>
          <CardContent>
            <CardTitle>{NARRATIVE_TYPES.HELPING_HANDS}</CardTitle>
            <CardBody>
              {getNarrativeDescription(NARRATIVE_TYPES.HELPING_HANDS)}
            </CardBody>
          </CardContent>
        </NarrativeCardImage>
      </NarrativeCard>
    </Wrapper>
  );
}
